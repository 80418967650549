import React, { useState, useEffect, useCallback } from "react";

import { useParams, useNavigate } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";

import styles from "./Passages.module.css";

import Cropper, { Area } from "react-easy-crop";

import Modal from "react-modal";

import getCroppedImg from "../ImageCrop/cropImage";

import {
  fetchCourseData,
  fetchPassages,
  uploadImage,
  addPassage,
  updatePassage,
  deletePassage,
} from "../API/API";

import { TableData } from "../API/types";


const TableDetails: React.FC = () => {
  const { tableName } = useParams<{ tableName: string }>();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [croppingDialogOpen, setCroppingDialogOpen] = useState(false);
  const [croppedBlob, setCroppedBlob] = useState<Blob | null>(null);
  const [editingRow, setEditingRow] = useState<string | null>(null);

  const [formData, setFormData] = useState<TableData>({
    Identifier: uuidv4(),
    Level: "1",
    Section: "",
    Genre: "",
    Question_1: "",
    Question_2: "",
    Question_3: "",
    Question_4: "",
    Options_1: ["", "", ""],
    Passage: "",
    Answer_1: "",
    Options_2: ["", "", ""],
    Answer_2: "",
    Options_3: ["", "", ""],
    Answer_3: "",
    Options_4: ["", "", ""],
    Answer_4: "",
    Description: "",
    Name: "",
    ImageUrl: "",
  });
  

  const [sections, setSections] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sectionsData = await fetchCourseData(formData.Level);
        console.log("sectionsData:", sectionsData);

        // Extract the section name from sectionsData
        const vocabularyList = sectionsData.Vocabulary_List.S;
        console.log("vocabularyList:", vocabularyList);

        // Update formData.Section with the section name
        setFormData((prevData) => ({
          ...prevData,
          Section: vocabularyList,
        }));

        // Fetch passages using the vocabularyList
        const passages = await fetchPassages(vocabularyList);
        console.log("passages:", passages);

        setTableData(passages);
        setError(null);
      } catch (err: any) {
        console.log("Error fetching data:", err);
        setError(err.message);
        setTableData([]);
      }
    };

    if (formData.Level) {
      fetchData();
    }
  }, [formData.Level]);


  const tableDataKeys = [
    "Identifier",
    "Level",
    "Section",
    "Genre",
    "Question_1",
    "Question_2",
    "Question_3",
    "Question_4",
    "Options_1",
    "Passage",
    "Answer_1",
    "Options_2",
    "Answer_2",
    "Options_3",
    "Answer_3",
    "Options_4",
    "Answer_4",
    "Description",
    "Name",
    "ImageUrl",
  ] as const;
  
  function isTableDataKey(key: string): key is keyof TableData {
    return (
      key in formData
    );
  }
  
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    identifier?: string
  ) => {
    const { name, value } = e.target;
    const nameParts = name.split("_");
  
    if (name === "Level") {
      setFormData((prevData) => ({
        ...prevData,
        Level: value,
      }));
    } else if (identifier) {
      // Handle changes in table data
      setTableData((prevData) =>
        prevData.map((item) => {
          if (item.Identifier === identifier) {
            if (nameParts.length === 3 && nameParts[0] === "Options") {
              // For options arrays in table data
              const optionsKey = `Options_${nameParts[1]}` as keyof TableData;
              const optionsIndex = parseInt(nameParts[2], 10);
              return {
                ...item,
                [optionsKey]: (item[optionsKey] as string[]).map((opt, idx) =>
                  idx === optionsIndex ? value : opt
                ),
              };
            } else if (isTableDataKey(name)) {
              // For other fields in table data
              return {
                ...item,
                [name]: value,
              };
            } else {
              return item;
            }
          } else {
            return item;
          }
        })
      );
    } else {
      // Handle changes in formData
      if (nameParts.length === 2 && nameParts[0].startsWith("Options")) {
        const optionsKey = `Options_${nameParts[0].replace("Options", "")}` as keyof TableData;
        const optionsIndex = parseInt(nameParts[1], 10);
        setFormData((prevData) => ({
          ...prevData,
          [optionsKey]: (prevData[optionsKey] as string[]).map((opt, idx) =>
            idx === optionsIndex ? value : opt
          ),
        }));
      }
       else if (isTableDataKey(name)) {
        // For other fields in formData
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImageFile(e.target.files[0]);

      setCroppingDialogOpen(true);
    }
  };

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const handleCrop = async () => {
    if (imageFile && croppedAreaPixels) {
      const croppedBlob = await getCroppedImg(
        URL.createObjectURL(imageFile),
        croppedAreaPixels
      );

      setCroppedBlob(croppedBlob);

      setCroppingDialogOpen(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let imageUrl = formData.ImageUrl;

    try {
      if (croppedBlob) {
        const newFileName = `${formData.Name.replace(
          /\s+/g,
          "_"
        )}_Image${imageFile!.name.substring(imageFile!.name.lastIndexOf("."))}`;

        imageUrl = await uploadImage(newFileName, croppedBlob);
      }

      // Destructure formData to exclude the 'Section' field for API

      const { Section, ...dataToSubmit } = formData;

      // Set `Level` to the selected `Lesson` (which is in Section)

      dataToSubmit.Level = formData.Section;

      // Send the dataToSubmit to the API without the 'Section' field

      await addPassage({
        ...dataToSubmit,

        ImageUrl: imageUrl,
      });

      // Add 'Section' back when updating local state to match the TableData interface

      setTableData((prevData) => [
        {
          ...dataToSubmit,

          Section: formData.Section, // Add Section back to match TableData structure

          ImageUrl: imageUrl,
        },

        ...prevData,
      ]);

      setError(null);

      setFormData({
        Identifier: uuidv4(),
        Level: "1",
        Section: "",
        Genre: "",
        Question_1: "",
        Question_2: "",
        Question_3: "",
        Question_4: "",
        Options_1: ["", "", ""],
        Passage: "",
        Answer_1: "",
        Options_2: ["", "", ""],
        Answer_2: "",
        Options_3: ["", "", ""],
        Answer_3: "",
        Options_4: ["", "", ""],
        Answer_4: "",
        Description: "",
        Name: "",
        ImageUrl: "",
      });
      
      
    } catch (err: any) {
      console.log("Error:", err);

      setError(err.message);
    }
  };

  const handleEdit = (identifier: string) => {
    setEditingRow(identifier);
  };

  const handleEditSubmit = async (identifier: string, level: string) => {
    const itemToUpdate = tableData.find(
      (item) => item.Identifier === identifier
    );
  
    if (!itemToUpdate) {
      setError("Item not found for editing");
      return;
    }
  
    try {
      const { Section, ...updatedData } = itemToUpdate; // Exclude the Section field
  
      await updatePassage(identifier, level, updatedData);
  
      setEditingRow(null);
  
      setTableData((prevData) =>
        prevData.map((item) =>
          item.Identifier === identifier ? { ...item, ...updatedData } : item
        )
      );
    } catch (err: any) {
      console.log("Error:", err);
      setError(err.message);
    }
  };
  

  const handleDelete = async (identifier: string, level: string) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        await deletePassage(identifier, level);
  
        setTableData((prevData) =>
          prevData.filter(
            (item) => item.Identifier !== identifier || item.Level !== level
          )
        );
      } catch (err: any) {
        console.log("Error:", err);
        setError(err.message);
      }
    }
  };
  

  const handleImageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    identifier: string
  ) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      const reader = new FileReader();

      reader.onloadend = () => {
        setTableData((prevData) =>
          prevData.map((item) =>
            item.Identifier === identifier
              ? { ...item, Image: reader.result as string }
              : item
          )
        );
      };

      reader.readAsDataURL(file); // This will convert the image to base64 for preview
    }
  };

  const renderCellValue = (value: any) => {
    if (Array.isArray(value)) {
      return value.join(", ");
    }

    return String(value);
  };

  return (
    <div className={styles.container}>
      <button onClick={() => navigate("/")} className={styles.backButton}>
        &larr; Back to Home
      </button>

      <h1 className={styles.title}>Passages</h1>

      <div className={styles.form}>
        <div className={styles.topRight}>
          <label className={styles.label}>
            Level:
            <select
              name="Level"
              value={formData.Level}
              onChange={handleChange}
              className={styles.input}
            >
              {[...Array(25).keys()].map((i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
          </label>

          <label className={styles.label}>
            Section:
            <input
              type="text"
              name="Section"
              value={formData.Section}
              readOnly
              className={styles.input}
            />
          </label>
        </div>
      </div>

      <h2>Add New Passage</h2>

      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Name:
            <input
              type="text"
              name="Name"
              value={formData.Name}
              onChange={handleChange}
              className={styles.input}
            />
          </label>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>
            Description:
            <textarea
              name="Description"
              value={formData.Description}
              onChange={handleChange}
              className={styles.textarea}
              rows={4}
            />
          </label>
        </div>
        <div className={styles.formGroup}>
    <label className={styles.label}>
      Genre:
      <input
        type="text"
        name="Genre"
        value={formData.Genre}
        onChange={handleChange}
        className={styles.input}
      />
    </label>
  </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Passage:
            <textarea
              name="Passage"
              value={formData.Passage}
              onChange={handleChange}
              className={styles.textarea}
              rows={4}
            />
          </label>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label className={styles.label}>
              Question 1:
              <input
                type="text"
                name="Question_1"
                value={formData.Question_1}
                onChange={handleChange}
                className={styles.input}
              />
            </label>
          </div>

          <div className={styles.formGroup}>
            <label className={styles.label}>
              Answer 1:
              <input
                type="text"
                name="Answer_1"
                value={formData.Answer_1}
                onChange={handleChange}
                className={styles.input}
              />
            </label>
          </div>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>
            Options 1:
            <div className={styles.optionsGroup}>
              <input
                type="text"
                name="Options1_0"
                value={formData.Options_1[0]}
                onChange={handleChange}
                className={styles.input}
              />

              <input
                type="text"
                name="Options1_1"
                value={formData.Options_1[1]}
                onChange={handleChange}
                className={styles.input}
              />

              <input
                type="text"
                name="Options1_2"
                value={formData.Options_1[2]}
                onChange={handleChange}
                className={styles.input}
              />
            </div>
          </label>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label className={styles.label}>
              Question 2:
              <input
                type="text"
                name="Question_2"
                value={formData.Question_2}
                onChange={handleChange}
                className={styles.input}
              />
            </label>
          </div>

          <div className={styles.formGroup}>
            <label className={styles.label}>
              Answer 2:
              <input
                type="text"
                name="Answer_2"
                value={formData.Answer_2}
                onChange={handleChange}
                className={styles.input}
              />
            </label>
          </div>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>
            Options 2:
            <div className={styles.optionsGroup}>
              <input
                type="text"
                name="Options2_0"
                value={formData.Options_2[0]}
                onChange={handleChange}
                className={styles.input}
              />

              <input
                type="text"
                name="Options2_1"
                value={formData.Options_2[1]}
                onChange={handleChange}
                className={styles.input}
              />

              <input
                type="text"
                name="Options2_2"
                value={formData.Options_2[2]}
                onChange={handleChange}
                className={styles.input}
              />
            </div>
          </label>
        </div>

        <div className={styles.formRow}>
    <div className={styles.formGroup}>
      <label className={styles.label}>
        Question 3:
        <input
          type="text"
          name="Question_3"
          value={formData.Question_3}
          onChange={handleChange}
          className={styles.input}
        />
      </label>
    </div>
    <div className={styles.formGroup}>
      <label className={styles.label}>
        Answer 3:
        <input
          type="text"
          name="Answer_3"
          value={formData.Answer_3}
          onChange={handleChange}
          className={styles.input}
        />
      </label>
    </div>
  </div>

  <div className={styles.formGroup}>
    <label className={styles.label}>
      Options 3:
      <div className={styles.optionsGroup}>
        {formData.Options_3.map((option, idx) => (
          <input
            key={idx}
            type="text"
            name={`Options3_${idx}`}
            value={option}
            onChange={handleChange}
            className={styles.input}
          />
        ))}
      </div>
    </label>
  </div>

  {/* Question 4 and its options */}
  <div className={styles.formRow}>
    <div className={styles.formGroup}>
      <label className={styles.label}>
        Question 4:
        <input
          type="text"
          name="Question_4"
          value={formData.Question_4}
          onChange={handleChange}
          className={styles.input}
        />
      </label>
    </div>
    <div className={styles.formGroup}>
      <label className={styles.label}>
        Answer 4:
        <input
          type="text"
          name="Answer_4"
          value={formData.Answer_4}
          onChange={handleChange}
          className={styles.input}
        />
      </label>
    </div>
  </div>

  <div className={styles.formGroup}>
    <label className={styles.label}>
      Options 4:
      <div className={styles.optionsGroup}>
        {formData.Options_4.map((option, idx) => (
          <input
            key={idx}
            type="text"
            name={`Options4_${idx}`}
            value={option}
            onChange={handleChange}
            className={styles.input}
          />
        ))}
      </div>
    </label>
  </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>
            Image:
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className={styles.input}
            />
          </label>
        </div>

        <button type="submit" className={styles.button}>
          Add Passage
        </button>
      </form>

      <table className={styles.table}>
      <thead>
        <tr>
          <th>Identifier</th>
          <th>Level</th>
          <th>Name</th>
          <th>Description</th>
          <th>Genre</th>          {/* New Field */}
          <th>Passage</th>
          <th>Question 1</th>
          <th>Answer 1</th>
          <th>Options 1</th>
          <th>Question 2</th>
          <th>Answer 2</th>
          <th>Options 2</th>
          <th>Question 3</th>     {/* New Field */}
          <th>Answer 3</th>       {/* New Field */}
          <th>Options 3</th>      {/* New Field */}
          <th>Question 4</th>     {/* New Field */}
          <th>Answer 4</th>       {/* New Field */}
          <th>Options 4</th>      {/* New Field */}
          <th>Image Url</th>
          <th>Edit / Delete</th>
        </tr>
      </thead>


      <tbody>
  {tableData.map((item, index) => (
    <tr key={index}>
      {/* Identifier */}
      <td>
        {editingRow === item.Identifier ? (
          <input
            type="text"
            name="Identifier"
            value={item.Identifier}
            onChange={(e) => handleChange(e, item.Identifier)}
            className={styles.input}
          />
        ) : (
          item.Identifier
        )}
      </td>

      {/* Level */}
      <td>
        {editingRow === item.Identifier ? (
          <input
            type="text"
            name="Level"
            value={item.Level}
            onChange={(e) => handleChange(e, item.Identifier)}
            className={styles.input}
          />
        ) : (
          item.Level
        )}
      </td>

      {/* Name */}
      <td>
        {editingRow === item.Identifier ? (
          <input
            type="text"
            name="Name"
            value={item.Name}
            onChange={(e) => handleChange(e, item.Identifier)}
            className={styles.input}
          />
        ) : (
          item.Name
        )}
      </td>

      {/* Description */}
      <td>
        {editingRow === item.Identifier ? (
          <input
            type="text"
            name="Description"
            value={item.Description}
            onChange={(e) => handleChange(e, item.Identifier)}
            className={styles.input}
          />
        ) : (
          item.Description
        )}
      </td>

      {/* Genre */}
      <td>
        {editingRow === item.Identifier ? (
          <input
            type="text"
            name="Genre"
            value={item.Genre}
            onChange={(e) => handleChange(e, item.Identifier)}
            className={styles.input}
          />
        ) : (
          item.Genre
        )}
      </td>

      {/* Passage */}
      <td>
        {editingRow === item.Identifier ? (
          <textarea
            name="Passage"
            value={item.Passage}
            onChange={(e) => handleChange(e, item.Identifier)}
            className={styles.textarea}
          />
        ) : (
          item.Passage
        )}
      </td>

      {/* Question 1 */}
      <td>
        {editingRow === item.Identifier ? (
          <input
            type="text"
            name="Question_1"
            value={item.Question_1}
            onChange={(e) => handleChange(e, item.Identifier)}
            className={styles.input}
          />
        ) : (
          item.Question_1
        )}
      </td>

      {/* Answer 1 */}
      <td>
        {editingRow === item.Identifier ? (
          <input
            type="text"
            name="Answer_1"
            value={item.Answer_1}
            onChange={(e) => handleChange(e, item.Identifier)}
            className={styles.input}
          />
        ) : (
          item.Answer_1
        )}
      </td>

      {/* Options 1 */}
      <td>
        {editingRow === item.Identifier
          ? item.Options_1.map((opt, idx) => (
              <input
                key={idx}
                type="text"
                name={`Options1_${idx}`}
                value={opt}
                onChange={(e) => handleChange(e, item.Identifier)}
                className={styles.input}
              />
            ))
          : renderCellValue(item.Options_1)}
      </td>

      {/* Question 2 */}
      <td>
        {editingRow === item.Identifier ? (
          <input
            type="text"
            name="Question_2"
            value={item.Question_2}
            onChange={(e) => handleChange(e, item.Identifier)}
            className={styles.input}
          />
        ) : (
          item.Question_2
        )}
      </td>

      {/* Answer 2 */}
      <td>
        {editingRow === item.Identifier ? (
          <input
            type="text"
            name="Answer_2"
            value={item.Answer_2}
            onChange={(e) => handleChange(e, item.Identifier)}
            className={styles.input}
          />
        ) : (
          item.Answer_2
        )}
      </td>

      {/* Options 2 */}
      <td>
        {editingRow === item.Identifier
          ? item.Options_2.map((opt, idx) => (
              <input
                key={idx}
                type="text"
                name={`Options2_${idx}`}
                value={opt}
                onChange={(e) => handleChange(e, item.Identifier)}
                className={styles.input}
              />
            ))
          : renderCellValue(item.Options_2)}
      </td>

      {/* Question 3 */}
      <td>
        {editingRow === item.Identifier ? (
          <input
            type="text"
            name="Question_3"
            value={item.Question_3}
            onChange={(e) => handleChange(e, item.Identifier)}
            className={styles.input}
          />
        ) : (
          item.Question_3
        )}
      </td>

      {/* Answer 3 */}
      <td>
        {editingRow === item.Identifier ? (
          <input
            type="text"
            name="Answer_3"
            value={item.Answer_3}
            onChange={(e) => handleChange(e, item.Identifier)}
            className={styles.input}
          />
        ) : (
          item.Answer_3
        )}
      </td>

      {/* Options 3 */}
      <td>
        {editingRow === item.Identifier
          ? item.Options_3.map((opt, idx) => (
              <input
                key={idx}
                type="text"
                name={`Options3_${idx}`}
                value={opt}
                onChange={(e) => handleChange(e, item.Identifier)}
                className={styles.input}
              />
            ))
          : renderCellValue(item.Options_3)}
      </td>

      {/* Question 4 */}
      <td>
        {editingRow === item.Identifier ? (
          <input
            type="text"
            name="Question_4"
            value={item.Question_4}
            onChange={(e) => handleChange(e, item.Identifier)}
            className={styles.input}
          />
        ) : (
          item.Question_4
        )}
      </td>

      {/* Answer 4 */}
      <td>
        {editingRow === item.Identifier ? (
          <input
            type="text"
            name="Answer_4"
            value={item.Answer_4}
            onChange={(e) => handleChange(e, item.Identifier)}
            className={styles.input}
          />
        ) : (
          item.Answer_4
        )}
      </td>

      {/* Options 4 */}
      <td>
        {editingRow === item.Identifier
          ? item.Options_4.map((opt, idx) => (
              <input
                key={idx}
                type="text"
                name={`Options4_${idx}`}
                value={opt}
                onChange={(e) => handleChange(e, item.Identifier)}
                className={styles.input}
              />
            ))
          : renderCellValue(item.Options_4)}
      </td>

      {/* Image Url */}
      <td>
        {editingRow === item.Identifier ? (
          <>
            <label
              htmlFor={`image-upload-${index}`}
              className={styles.imageUploadLabel}
            >
              Upload Image
              <input
                id={`image-upload-${index}`}
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(e, item.Identifier)}
                className={styles.imageUploadInput}
              />
            </label>
            {item.ImageUrl && (
              <img
                src={item.ImageUrl}
                alt="Preview"
                className={styles.imagePreview}
              />
            )}
          </>
        ) : item.ImageUrl ? (
          <img
            src={item.ImageUrl}
            alt={item.Name}
            className={styles.imagePreview}
          />
        ) : (
          "No Image"
        )}
      </td>

      {/* Edit / Delete Buttons */}
      <td>
        {editingRow === item.Identifier ? (
          <>
            <div className={styles.buttonWrapper}>
              <button
                onClick={() =>
                  handleEditSubmit(item.Identifier, item.Level)
                }
                className={styles.button}
              >
                Submit
              </button>
            </div>
            <div className={styles.buttonWrapper}>
              <button
                onClick={() => setEditingRow(null)}
                className={styles.button}
              >
                Cancel
              </button>
            </div>
          </>
        ) : (
          <>
            <div className={styles.buttonWrapper}>
              <button
                onClick={() => handleEdit(item.Identifier)}
                className={styles.button}
              >
                Edit
              </button>
            </div>
            <div className={styles.buttonWrapper}>
              <button
                onClick={() =>
                  handleDelete(item.Identifier, item.Level)
                }
                className={styles.button}
              >
                Delete
              </button>
            </div>
          </>
        )}
      </td>
    </tr>
  ))}
</tbody>
</table>

      <Modal
        isOpen={croppingDialogOpen}
        onRequestClose={() => setCroppingDialogOpen(false)}
      >
        {imageFile && (
          <div className={styles.cropContainer}>
            <Cropper
              image={URL.createObjectURL(imageFile)}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />

            <button
              onClick={handleCrop}
              className={`${styles.button} ${styles.cropButton}`}
            >
              Crop Image
            </button>
          </div>
        )}
      </Modal>

      {error && <div className={styles.error}>Error: {error}</div>}
    </div>
  );
};

export default TableDetails;
