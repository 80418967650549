import React, { useState, useEffect, useCallback } from "react";

import { useParams, useNavigate } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";

import styles from "./Vocabulary.module.css";

import Cropper, { Area } from "react-easy-crop";

import Modal from "react-modal";

import getCroppedImg from "../ImageCrop/cropImage";

import {
  fetchCourseData,
  fetchVocabularyData,
  addVocabulary,
  updateVocabulary,
  deleteVocabulary,
  uploadImage,
} from "../API/API"; // Import API functions

import { CourseData, VocabularyData } from "../API/types";

const VocabularyDetails: React.FC = () => {
  const { tableName } = useParams<{ tableName: string }>();

  const navigate = useNavigate();

  const [tableData, setTableData] = useState<VocabularyData[]>([]);

  const [error, setError] = useState<string | null>(null);

  const [editingRow, setEditingRow] = useState<string | null>(null);

  const [confirmDeleteRow, setConfirmDeleteRow] = useState<string | null>(null);

  const [formData, setFormData] = useState<VocabularyData>({
    Identifier: uuidv4(),

    Level: "1",

    Section: "",

    SpanishWord: "",

    EnglishWord: "",

    ImageUrl: "",

    SpanishOptions: ["", "", ""],

    EnglishOptions: ["", "", ""],
  });

  const [imageFile, setImageFile] = useState<File | null>(null);

  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const [zoom, setZoom] = useState(1);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const [croppingDialogOpen, setCroppingDialogOpen] = useState(false);

  const [croppedBlob, setCroppedBlob] = useState<File | null>(null);

  // const [courseData, setCourseData] = useState<CourseData[]>()

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("form", formData);

        const response = await fetchCourseData(formData.Level);

        // console.log("Raw response:", response);

        const vocabularyList = response.Vocabulary_List?.S || "";

        setFormData((prevData) => ({
          ...prevData,

          Section: vocabularyList,
        }));
      } catch (err: any) {
        console.log("Error fetching course data:", err);

        setError(err.message);

        setTableData([]); // Clear the table data on error
      }
    };

    if (formData.Level) {
      fetchData();
    }
  }, [formData.Level]);

  useEffect(() => {
    const fetchVocabulary = async () => {
      try {
        const items = await fetchVocabularyData(formData.Section); // Call function from api.ts

        setTableData(items);
      } catch (err: any) {
        console.log("Error fetching vocabulary data:", err);

        setError(err.message);

        setTableData([]); // Clear the table data on error
      }
    };

    if (formData.Section) {
      fetchVocabulary();
    }
  }, [formData.Section, tableName]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      let imageUrl = formData.ImageUrl;

      if (croppedBlob) {
        const newFileName = `${formData.EnglishWord.replace(
          /\s+/g,
          "_"
        )}_Spanish${imageFile!.name.substring(
          imageFile!.name.lastIndexOf(".")
        )}`;

        imageUrl = await uploadImage(newFileName, croppedBlob); // Updated function call
      }

      const formattedData = {
        ...formData,

        ImageUrl: imageUrl,

        SpanishOptions: formData.SpanishOptions.filter(
          (option) => option.trim() !== ""
        ),

        EnglishOptions: formData.EnglishOptions.filter(
          (option) => option.trim() !== ""
        ),
      };

      await addVocabulary(formattedData); // Call function from api.ts

      setTableData([formattedData, ...tableData]);

      setError(null);

      // Reset other fields but keep Level and Section unchanged

      setFormData({
        Identifier: uuidv4(),

        Level: formData.Level, // Keep the current Level value

        Section: formData.Section, // Keep the current Section value

        SpanishWord: "",

        EnglishWord: "",

        ImageUrl: "",

        SpanishOptions: ["", "", ""],

        EnglishOptions: ["", "", ""],
      });

      setImageFile(null);

      setCroppedBlob(null);
    } catch (err: any) {
      console.log("Error submitting form:", err);

      setError(err.message);
    }
  };

  const handleEditSubmit = async (identifier: string, level: string) => {
    const itemToUpdate = tableData.find(
      (item) => item.Identifier === identifier
    );

    if (!itemToUpdate) {
      setError("Item not found for editing");

      return;
    }

    try {
      let imageUrl = itemToUpdate.ImageUrl;

      if (croppedBlob) {
        const newFileName = `${itemToUpdate.EnglishWord.replace(
          /\s+/g,
          "_"
        )}_Spanish${imageFile!.name.substring(
          imageFile!.name.lastIndexOf(".")
        )}`;

        imageUrl = await uploadImage(newFileName, croppedBlob); // Updated function call
      }

      const updatedData = {
        Identifier: itemToUpdate.Identifier,

        Level: itemToUpdate.Level,

        Section: itemToUpdate.Section,

        SpanishWord: itemToUpdate.SpanishWord,

        EnglishWord: itemToUpdate.EnglishWord,

        ImageUrl: imageUrl,

        SpanishOptions: itemToUpdate.SpanishOptions.filter(
          (option) => option.trim() !== ""
        ),

        EnglishOptions: itemToUpdate.EnglishOptions.filter(
          (option) => option.trim() !== ""
        ),
      };

      await updateVocabulary(identifier, level, updatedData); // Call function from api.ts

      setEditingRow(null);

      setTableData((prevData) =>
        prevData.map((item) =>
          item.Identifier === identifier ? { ...item, ...updatedData } : item
        )
      );
    } catch (err: any) {
      console.log("Error updating item:", err);

      setError(err.message);
    }
  };

  const handleDelete = async (identifier: string, level: string) => {
    const itemToDelete = tableData.find(
      (item) => item.Identifier === identifier
    );

    if (!itemToDelete) {
      setError("Item not found for deletion");

      return;
    }

    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        // Pass the full VocabularyData to deleteVocabulary

        await deleteVocabulary({
          Identifier: identifier,

          Level: level,

          Section: itemToDelete.Section,

          SpanishWord: itemToDelete.SpanishWord,

          EnglishWord: itemToDelete.EnglishWord,

          ImageUrl: itemToDelete.ImageUrl,

          SpanishOptions: itemToDelete.SpanishOptions,

          EnglishOptions: itemToDelete.EnglishOptions,
        }); // Call function from api.ts

        setTableData((prevData) =>
          prevData.filter(
            (item) => item.Identifier !== identifier || item.Level !== level
          )
        );
      } catch (err: any) {
        console.log("Error deleting item:", err);

        setError(err.message);
      }
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    identifier?: string
  ) => {
    const { name, value } = e.target;

    const [key, index] = name.split("_");

    if (identifier) {
      setTableData((prevData) =>
        prevData.map((item) =>
          item.Identifier === identifier
            ? {
                ...item,

                [key === "SpanishOptions" || key === "EnglishOptions"
                  ? key
                  : name]:
                  key === "SpanishOptions" || key === "EnglishOptions"
                    ? item[key as "SpanishOptions" | "EnglishOptions"].map(
                        (opt, idx) =>
                          idx === parseInt(index, 10) ? value : opt
                      )
                    : value,
              }
            : item
        )
      );
    } else {
      if (key === "SpanishOptions" || key === "EnglishOptions") {
        setFormData((prevData) => {
          const optionsKey =
            key === "SpanishOptions" ? "SpanishOptions" : "EnglishOptions";

          const newOptions = [
            ...prevData[optionsKey as "SpanishOptions" | "EnglishOptions"],
          ];

          newOptions[parseInt(index, 10)] = value;

          return {
            ...prevData,

            [optionsKey]: newOptions,
          };
        });
      } else {
        setFormData((prevData) => ({
          ...prevData,

          [name]: value,
        }));
      }
    }
  };

  const handleEdit = (identifier: string) => {
    setEditingRow(identifier);

    setConfirmDeleteRow(null);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImageFile(e.target.files[0]);

      setCroppingDialogOpen(true);
    }
  };

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const handleCrop = async () => {
    if (imageFile && croppedAreaPixels) {
      const croppedBlob = await getCroppedImg(
        URL.createObjectURL(imageFile),
        croppedAreaPixels
      );

      // Create a new File from the cropped Blob

      const croppedFile = new File(
        [croppedBlob], // Pass the Blob array

        imageFile.name, // Original file name or give a new name

        { type: imageFile.type } // Set the MIME type from the original file
      );

      setCroppedBlob(croppedFile as File); // Set as a File

      setCroppingDialogOpen(false);
    }
  };

  const renderCellValue = (value: any) => {
    // console.log('Rendering cell value:', value);

    if (Array.isArray(value)) {
      return value.map((option, index) => <div key={index}>{option}</div>);
    }

    return String(value);
  };

  return (
    <div className={styles.container}>
      <button onClick={() => navigate("/")} className={styles.backButton}>
        &larr; Back to Home
      </button>

      <h1 className={styles.title}>Vocabulary</h1>

      <div className={styles.form}>
        <div className={styles.topRight}>
          <label className={styles.label}>
            Level:
            <select
              name="Level"
              value={formData.Level}
              onChange={handleChange}
              className={styles.input}
            >
              {[...Array(100).keys()].map((i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
          </label>

          <label className={styles.label}>
            Section:
            <input
              type="text"
              name="Section"
              value={formData.Section}
              readOnly
              className={styles.input}
            />
          </label>
        </div>
      </div>

      <h2>Add New Vocabulary</h2>

      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Spanish Word:
            <input
              type="text"
              name="SpanishWord"
              value={formData.SpanishWord}
              onChange={handleChange}
              className={styles.input}
            />
          </label>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>
            English Word:
            <input
              type="text"
              name="EnglishWord"
              value={formData.EnglishWord}
              onChange={handleChange}
              className={styles.input}
            />
          </label>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>
            Image:
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className={styles.input}
            />
          </label>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>
            Spanish Options:
            <div className={styles.optionsGroup}>
              <input
                type="text"
                name="SpanishOptions_0"
                value={formData.SpanishOptions[0]}
                onChange={handleChange}
                className={styles.input}
              />

              <input
                type="text"
                name="SpanishOptions_1"
                value={formData.SpanishOptions[1]}
                onChange={handleChange}
                className={styles.input}
              />

              <input
                type="text"
                name="SpanishOptions_2"
                value={formData.SpanishOptions[2]}
                onChange={handleChange}
                className={styles.input}
              />
            </div>
          </label>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>
            English Options:
            <div className={styles.optionsGroup}>
              <input
                type="text"
                name="EnglishOptions_0"
                value={formData.EnglishOptions[0]}
                onChange={handleChange}
                className={styles.input}
              />

              <input
                type="text"
                name="EnglishOptions_1"
                value={formData.EnglishOptions[1]}
                onChange={handleChange}
                className={styles.input}
              />

              <input
                type="text"
                name="EnglishOptions_2"
                value={formData.EnglishOptions[2]}
                onChange={handleChange}
                className={styles.input}
              />
            </div>
          </label>
        </div>

        <button type="submit" className={styles.button}>
          Add Vocabulary
        </button>
      </form>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>Identifier</th>

            <th>Section</th>

            <th>Spanish Word</th>

            <th>English Word</th>

            <th>Image</th>

            <th>Spanish Options</th>

            <th>English Options</th>

            <th>Edit / Delete</th>
          </tr>
        </thead>

        <tbody>
          {!formData.Section || tableData.length === 0 ? (
            <tr>
              <td colSpan={8} className={styles.noDataMessage}>
                {formData.Section
                  ? "No vocabulary words found for this section."
                  : "No section available for this level."}
              </td>
            </tr>
          ) : (
            tableData.map((item, index) => (
              <tr key={index}>
                <td>
                  {editingRow === item.Identifier ? (
                    <input
                      type="text"
                      name="Identifier"
                      value={item.Identifier}
                      onChange={(e) => handleChange(e, item.Identifier)}
                      className={styles.input}
                    />
                  ) : (
                    item.Identifier
                  )}
                </td>

                <td>
                  {editingRow === item.Identifier ? (
                    <input
                      type="text"
                      name="Section"
                      value={item.Section}
                      onChange={(e) => handleChange(e, item.Identifier)}
                      className={styles.input}
                    />
                  ) : (
                    item.Section
                  )}
                </td>

                <td>
                  {editingRow === item.Identifier ? (
                    <input
                      type="text"
                      name="SpanishWord"
                      value={item.SpanishWord}
                      onChange={(e) => handleChange(e, item.Identifier)}
                      className={styles.input}
                    />
                  ) : (
                    item.SpanishWord
                  )}
                </td>

                <td>
                  {editingRow === item.Identifier ? (
                    <input
                      type="text"
                      name="EnglishWord"
                      value={item.EnglishWord}
                      onChange={(e) => handleChange(e, item.Identifier)}
                      className={styles.input}
                    />
                  ) : (
                    item.EnglishWord
                  )}
                </td>

                <td>
                  {editingRow === item.Identifier ? (
                    <>
                      <img
                        src={item.ImageUrl}
                        alt={item.SpanishWord}
                        className={styles.image}
                      />

                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className={styles.input}
                      />
                    </>
                  ) : (
                    <img
                      src={item.ImageUrl}
                      alt={item.SpanishWord}
                      className={styles.image}
                    />
                  )}
                </td>

                <td>
                  {editingRow === item.Identifier
                    ? item.SpanishOptions.map((opt, idx) => (
                        <input
                          key={idx}
                          type="text"
                          name={`SpanishOptions_${idx}`}
                          value={opt}
                          onChange={(e) => handleChange(e, item.Identifier)}
                          className={styles.input}
                        />
                      ))
                    : renderCellValue(item.SpanishOptions)}
                </td>

                <td>
                  {editingRow === item.Identifier
                    ? item.EnglishOptions.map((opt, idx) => (
                        <input
                          key={idx}
                          type="text"
                          name={`EnglishOptions_${idx}`}
                          value={opt}
                          onChange={(e) => handleChange(e, item.Identifier)}
                          className={styles.input}
                        />
                      ))
                    : renderCellValue(item.EnglishOptions)}
                </td>

                <td>
                  {editingRow === item.Identifier ? (
                    <>
                      <div>
                        <button
                          onClick={() =>
                            handleEditSubmit(item.Identifier, item.Level)
                          }
                          className={styles.button}
                        >
                          Submit
                        </button>
                      </div>

                      <div>
                        <button
                          onClick={() => setEditingRow(null)}
                          className={styles.button}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <button
                          onClick={() => handleEdit(item.Identifier)}
                          className={styles.button}
                        >
                          Edit
                        </button>
                      </div>

                      <div>
                        <button
                          onClick={() =>
                            handleDelete(item.Identifier, item.Level)
                          }
                          className={styles.button}
                        >
                          Delete
                        </button>
                      </div>
                    </>
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      <Modal
        isOpen={croppingDialogOpen}
        onRequestClose={() => setCroppingDialogOpen(false)}
      >
        {imageFile && (
          <div className={styles.cropContainer}>
            <Cropper
              image={URL.createObjectURL(imageFile)}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />

            <button
              onClick={handleCrop}
              className={`${styles.button} ${styles.cropButton}`}
            >
              Crop Image
            </button>
          </div>
        )}
      </Modal>

      {error && <div className={styles.error}>Error: {error}</div>}
    </div>
  );
};

export default VocabularyDetails;
